<template>
  <section class="section">
    <div class="is-flex is-align-items-center is-justify-content-space-between mb-4">
      <h2 class="is-size-3 has-text-weight-bold mb-0 mr-6" style="flex: none;">
        {{ $t('route.overview') }}
      </h2>
      <div class="is-flex is-justify-content-space-between w-100 stats">
        <template v-if="statsOverview">
          <div>
            <p class="is-uppercase is-size-7">
              {{ $t('overview.total-threats') }}
            </p>
            <h3 class="is-size-5">
              {{ statsOverview.all.toLocaleString() }}
            </h3>
          </div>
          <div class="is-hidden-touch is-hidden-desktop-only is-block-widescreen">
            <p v-if="$refs.rangepicker" class="is-uppercase is-size-7">
              {{ $t('overview.recent-threats') }} - {{ $refs.rangepicker.text }}
            </p>
            <h3 class="is-size-5">
              {{ statsOverview.current.toLocaleString() }}
            </h3>
          </div>
          <div class="is-hidden-touch is-block-desktop">
            <p class="is-uppercase is-size-7">
              {{ $t('route.sensors') }}
            </p>
            <h3 class="is-size-5">
              {{ systemStats.sensors }}
            </h3>
          </div>
          <div class="is-hidden-touch is-block-desktop">
            <p class="is-uppercase is-size-7">
              {{ $t('clients') }}
            </p>
            <h3 class="is-size-5">
              {{ systemStats.clients }}
            </h3>
          </div>
          <div class="">
            <p class="is-uppercase is-size-7">
              {{ $t('bandwidth') }}
            </p>
            <h3 class="is-size-5">
              {{ bandwidthHuman(systemStats.bandwidth_avg * 8) }}
            </h3>
          </div>
          <div class="is-hidden-touch is-hidden-desktop-only is-block-widescreen">
            <p class="is-uppercase is-size-7">
              {{ $t('traffic') }}
            </p>
            <h3 class="is-size-5">
              {{ formatTraffic(systemStats.traffic) }}
            </h3>
          </div>
        </template>
        <h4 v-else>
          {{ $t('loading') }}
        </h4>
      </div>
      <range-picker
        ref="rangepicker"
        :range.sync="dates"
        :lookup.sync="dateRange"
        :disabled="loading"
        min-hour
        style="flex: none;"
      />
    </div>
    <div class="foobar is-flex my-5" :style="{ height: foobarHeight ? foobarHeight + 'px' : null }">
      <div style="display: block; width: 100%;">
        <world-map
          v-resize="onResize" :data="foobar" :loading="loading"
        />
      </div>
      <div v-if="!loading" class="is-flex is-flex-direction-column" style="width: 32%; flex: none; gap: 1em;">
        <div class="w-100 is-flex is-flex-direction-column" style="height: 50%">
          <strong style="flex: none">{{ $t('overview.recent-threats') }}</strong>
          <overlay-scrollbars style="flex: 1;" :options="{ className: isDark ? 'os-theme-light' : 'os-theme-dark' }" class="pr-3">
            <ul class="is-size-7">
              <li v-for="item in recentThreats" :key="item.id">
                <router-link class="is-flex w-100 is-justify-content-space-between" :to="{name: 'threats-all'}">
                  <span class="truncate">{{ item.resource }}</span> <span style="flex: none">{{ item.seen_at | dmyhs }}</span>
                </router-link>
              </li>
            </ul>
          </overlay-scrollbars>
        </div>
        <div class="w-100 is-flex is-flex-direction-column" style="height: 50%;">
          <strong style="flex: none">{{ $t('overview.recent-alerts') }}</strong>
          <overlay-scrollbars style="flex: 1;" :options="{ className: isDark ? 'os-theme-light' : 'os-theme-dark' }" class="pr-3">
            <ul class="is-size-7">
              <li v-for="item in recentAlerts" :key="item.id">
                <router-link class="is-flex w-100 is-justify-content-space-between" :to="{name: 'alerts-all'}">
                  <span class="truncate">{{ alertDetails(item) }}</span> <span style="flex: none">{{ item.created_at | dmyhs }}</span>
                </router-link>
              </li>
            </ul>
          </overlay-scrollbars>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-two-thirds">
        <box>
          <p slot="header">
            {{ $t('threats.histogram-origin') }}
          </p>
          <chart
            type="timeseries"
            :series="statsSeriesAllWithRange"
            :options="statsOptionsAllWithRange"
            :loading="loading"
            lazy
            style="height: 300px"
          />
        </box>
      </div>
      <div class="column is-one-third">
        <box>
          <p slot="header">
            {{ $t('threats.pie-severity') }}
          </p>
          <chart
            type="pie"
            :series="statsSeriesSeverity"
            :options="{tooltip: { format: { value: function (value, ratio, id) { return value } } }}"
            :loading="loading"
            lazy
            style="height: 300px"
          />
        </box>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <box>
          <p slot="header">
            {{ $t('threats.bar-resource') }}
          </p>
          <chart
            type="bar"
            :series="statsSeriesResource"
            :options="statsOptionsResource"
            :loading="loading"
            lazy
            style="height: 300px"
          />
        </box>
      </div>
      <div class="column is-half">
        <box>
          <p slot="header">
            {{ $t('threats.bar-src') }}
          </p>
          <chart
            type="bar"
            :series="statsSeriesSrc"
            :options="statsOptionsSrc"
            :loading="loading"
            lazy
            style="height: 300px"
          />
        </box>
      </div>
    </div>
    <div v-for="sensor in sensors" :key="sensor.id" class="mb-2">
      <box>
        <p slot="header" class="is-uppercase">
          {{ sensor.name }}
        </p>
        <div class="is-flex">
          <div style="position: relative; flex: none; width: 33%">
            <chart
              type="gauge"
              :series="sensor.resourceLatest"
              :loading="loading"
              lazy
              style="height: 350px"
            />
            <div class="is-flex is-justify-content-center" style="position: absolute; width: 100%; top: 0; left: 0; text-align: center;">
              <div v-for="(bandwidth, key) in sensor.bandwidth" :key="key" class="py-2 px-4">
                <p class="is-uppercase is-size-7">
                  {{ key }}
                </p>
                {{ bandwidthHuman(bandwidth.received_bytes_avg * 8) }}
              </div>
            </div>
          </div>
          <div style="flex: 1; overflow: hidden;">
            <chart
              type="timeseries"
              :series="sensor.resourceSeries"
              :options="statsOptionsMonitor"
              :loading="loading"
              lazy
              style="height: 350px"
            />
          </div>
        </div>
      </box>
    </div>
  </section>
</template>

<script>
import { interpolateWarm, interpolateCool, scaleSqrt } from 'd3'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import RangePicker from '@/components/RangePicker'
import Chart from '@/components/Chart'
import WorldMap from '@/components/WorldMap'
import { statsSeriesBar, statsSeriesPie, statsSeriesTimeseries, sensorSeriesGauge, bandwidthHuman, formatTraffic } from '@/utils'
import { ThreatSeverities } from '@/store'
import { mapState } from 'vuex'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue'
import 'overlayscrollbars/css/OverlayScrollbars.min.css'

export default {
  components: { RangePicker, Chart, WorldMap, 'overlay-scrollbars': OverlayScrollbarsComponent },
  data () {
    return {
      dateRange: 'hour-24',
      dates: [],
      loading: false,
      statsOverview: null,
      statsAllWithRange: null,
      statsResource: null,
      statsSrc: null,
      statsSeverity: null,
      sensors: null,
      wsSensorsMonitor: null,
      wsSensorsMonitorRetry: null,
      wsSensorsBandwidth: null,
      wsSensorsBandwidthRetry: null,
      wsThreat: null,
      wsThreatRetry: null,
      wsAlert: null,
      wsAlertRetry: null,
      foobar: {},
      foobarHeight: 0,
      recentThreats: [],
      recentAlerts: []
    }
  },
  computed: {
    ...mapState(['version', 'license', 'systemStats']),
    isDark () {
      return !!this.$store.state.ui.dark
    },
    wst () {
      return this.$store.getters.wst
    },
    rangeFormat () {
      return '%d/%m/%Y %H:%M'
    },
    categoriesSeverity () {
      return ThreatSeverities
    },
    categoriesSensorResource () {
      return ['cpu', 'disk', 'ram']
    },
    sensorIDs () {
      if (!this.sensors) {
        return []
      }

      return Object.keys(this.sensors)
    },
    threatScale () {
      return scaleSqrt()
        .domain([0, 30, 10000])
        .range([0, 20, 200])
    },
    statsSeriesAllWithRange () {
      if (!this.statsAllWithRange || !this.statsAllWithRange.length) {
        return {}
      }

      return statsSeriesTimeseries(this.statsAllWithRange, null, { scale: this.threatScale })
    },
    statsSeriesResource () {
      if (!this.statsResource) {
        return {}
      }

      return statsSeriesBar(this.statsResource, null, 'Threats', { scale: this.threatScale })
    },
    statsSeriesSrc () {
      if (!this.statsSrc) {
        return {}
      }

      return statsSeriesBar(this.statsSrc, null, 'Threats', { scale: this.threatScale })
    },
    statsSeriesSeverity () {
      if (!this.statsSeverity) {
        return {}
      }

      return statsSeriesPie(this.statsSeverity, this.categoriesSeverity, 'Threats')
    },
    statsOptionsAllWithRange () {
      const self = this
      return {
        axis: {
          x: {
            tick: {
              format: this.rangeFormat
            }
          },
          y: {
            tick: {
              format (d) {
                return Math.round(self.threatScale.invert(d))
              }
            }
          }
        }
      }
    },
    statsOptionsMonitor () {
      const self = this
      return {
        axis: {
          x: {
            tick: {
              format: this.rangeFormat
            }
          },
          y: {
            label: 'Usage (%)'
          },
          y2: {
            label: 'Bandwidth',
            show: true
          }
        },
        tooltip: {
          format: {
            value (value, ratio, id) {
              if (self.categoriesSensorResource.indexOf(id) === -1) {
                return bandwidthHuman(value * 8)
              }
              return Number(value).toFixed(2) + '%'
            }
          }
        }
      }
    },
    statsOptionsResource () {
      const self = this
      return {
        axis: {
          y: {
            tick: {
              format (d) {
                return Math.round(self.threatScale.invert(d))
              }
            }
          }
        },
        data: {
          color (color, d) {
            if (typeof d.index === 'undefined') {
              return color
            }

            return interpolateWarm((d.index + 1) / 10)
          }
        }
      }
    },
    statsOptionsSrc () {
      const self = this
      return {
        axis: {
          y: {
            tick: {
              format (d) {
                return Math.round(self.threatScale.invert(d))
              }
            }
          }
        },
        data: {
          color (color, d) {
            if (typeof d.index === 'undefined') {
              return color
            }

            return interpolateCool((d.index + 1) / 10)
          }
        }
      }
    }
  },
  watch: {
    wst: {
      immediate: true,
      handler (v) {
        if (!v) {
          return
        }

        this.wsThreat = new WebSocket(`${window.location.protocol === 'http:' ? 'ws:' : 'wss:'}//${window.location.host}/ws/${this.wst}/feed/threats`)
        this.wsAlert = new WebSocket(`${window.location.protocol === 'http:' ? 'ws:' : 'wss:'}//${window.location.host}/ws/${this.wst}/feed/alerts`)
      }
    },
    dates (v) {
      if (!v || !v.length) {
        return
      }

      this.loadStatsOverview()
      this.loadStatsAllWithRange()
      this.loadStatsResource()
      this.loadStatsSrc()
      this.loadStatsDstGeo()
      this.loadStatsSeverity()
      this.loadSensorMonitoring().then(this.loadSensorMonitoringLatest)
    },
    wsThreat (v, o) {
      if (o) {
        o.close()
      }

      v.onopen = e => {
        this.wsThreatRetry = null
      }

      v.onclose = e => {
        if (this._isBeingDestroyed) {
          return
        }

        if (!this.wsThreatRetry) {
          this.wsThreatRetry = new Date()
        }

        setTimeout(() => {
          const diff = differenceInSeconds(new Date(), this.wsThreatRetry)
          if (diff >= 60) {
            return
          }

          console.log('ws', 'reconnect', v.url, this.wsThreatRetry)
          this.wsThreat = new WebSocket(v.url)
        }, 5000)
      }

      v.onmessage = e => {
        const data = JSON.parse(e.data)
        if (!data.data) {
          return
        }

        if (data.action !== 'INSERT') {
          return
        }

        this.recentThreats = [data.data, ...this.recentThreats.slice(0, 20)]
      }
    },
    wsAlert (v, o) {
      if (o) {
        o.close()
      }

      v.onopen = e => {
        this.wsAlertRetry = null
      }

      v.onclose = e => {
        if (this._isBeingDestroyed) {
          return
        }

        if (!this.wsAlertRetry) {
          this.wsAlertRetry = new Date()
        }

        setTimeout(() => {
          const diff = differenceInSeconds(new Date(), this.wsAlertRetry)
          if (diff >= 60) {
            return
          }

          console.log('ws', 'reconnect', v.url, this.wsAlertRetry)
          this.wsAlert = new WebSocket(v.url)
        }, 5000)
      }

      v.onmessage = e => {
        const data = JSON.parse(e.data)
        if (!data.data) {
          return
        }

        if (data.action !== 'INSERT') {
          return
        }

        this.recentAlerts = [data.data, ...this.recentAlerts.slice(0, 20)]
      }
    },
    wsSensorsMonitor (v, o) {
      if (o) {
        o.close()
      }

      v.onopen = e => {
        this.wsSensorsMonitorRetry = null
      }

      v.onclose = e => {
        if (this._isBeingDestroyed) {
          return
        }

        if (!this.wsSensorsMonitorRetry) {
          this.wsSensorsMonitorRetry = new Date()
        }

        setTimeout(() => {
          const diff = differenceInSeconds(new Date(), this.wsSensorsMonitorRetry)
          if (diff >= 60) {
            return
          }

          console.log('ws', 'reconnect', v.url, this.wsSensorsMonitorRetry)
          this.wsSensorsMonitor = new WebSocket(v.url)
        }, 5000)
      }

      v.onmessage = e => {
        const data = JSON.parse(e.data)
        if (!data.data) {
          return
        }

        if (data.action !== 'INSERT') {
          return
        }

        data.data.cpu = data.data.cpu_usage
        data.data.ram = data.data.ram_usage
        data.data.disk = data.data.disk_usage

        if (!this.sensors || !this.sensors[data.data.sensor_id]) {
          return
        }

        this.sensors[data.data.sensor_id].resourceLatest = sensorSeriesGauge(data.data, this.categoriesSensorResource)
      }
    },
    wsSensorsBandwidth (v, o) {
      if (o) {
        o.close()
      }

      v.onopen = e => {
        this.wsSensorsBandwidthRetry = null
      }

      v.onclose = e => {
        if (this._isBeingDestroyed) {
          return
        }

        if (!this.wsSensorsBandwidthRetry) {
          this.wsSensorsBandwidthRetry = new Date()
        }

        setTimeout(() => {
          const diff = differenceInSeconds(new Date(), this.wsSensorsBandwidthRetry)
          if (diff >= 60) {
            return
          }

          console.log('ws', 'reconnect', v.url, this.wsSensorsBandwidthRetry)
          this.wsSensorsBandwidth = new WebSocket(v.url)
        }, 5000)
      }

      v.onmessage = e => {
        const data = JSON.parse(e.data)
        if (!data.data) {
          return
        }

        if (data.action !== 'INSERT') {
          return
        }

        if (!this.sensors[data.data.sensor_id]) {
          return
        }

        if (!this.sensors[data.data.sensor_id].bandwidth) {
          this.sensors[data.data.sensor_id].bandwidth = {}
        }

        const bandwidth = this.sensors[data.data.sensor_id].bandwidth
        const i = data.data.interface

        bandwidth[i] = {
          received_bytes_avg: data.data.received_bytes / data.data.interval
        }
      }
    }
  },
  created () {
    this.loadRecentThreats()
    this.loadRecentAlerts()
  },
  beforeDestroy () {
    if (this.wsAlert) {
      this.wsAlert.close()
    }

    if (this.wsThreat) {
      this.wsThreat.close()
    }

    if (this.wsSensorsBandwidth) {
      this.wsSensorsBandwidth.close()
    }

    if (this.wsSensorsMonitor) {
      this.wsSensorsMonitor.close()
    }
  },
  methods: {
    bandwidthHuman,
    formatTraffic,
    alertDetails (item) {
      return item?.details?.object?.resource || item?.details?.name
    },
    onResize ({ height }) {
      this.foobarHeight = height
    },
    pullSensorMonitoringLatest (ids) {
      return Promise.all([
        ...ids.map(id => {
          return this.$http.get(`/api/v1/sensor-monitoring/${id}/last`)
            .then(body => body?.data)
            .then(data => {
              if (!data) {
                return
              }

              this.sensors[id].resourceLatest = sensorSeriesGauge(data.data || {}, this.categoriesSensorResource)
            })
        }),
        ...ids.map(id => {
          return this.$http.get(`/api/v1/sensor-monitoring/bandwidth/${id}/last`)
            .then(body => body?.data)
            .then(data => {
              if (!data) {
                return
              }

              this.sensors[id].bandwidth = data.data
            })
        })
      ])
    },
    loadSensorMonitoringLatest () {
      if (!this.wst || !this.sensorIDs) {
        return
      }

      this.wsSensorsMonitor = new WebSocket(`${window.location.protocol === 'http:' ? 'ws:' : 'wss:'}//${window.location.host}/ws/${this.wst}/feed/sensors_monitor`)
      this.wsSensorsBandwidth = new WebSocket(`${window.location.protocol === 'http:' ? 'ws:' : 'wss:'}//${window.location.host}/ws/${this.wst}/feed/sensor_bandwidth_monitor`)
      this.pullSensorMonitoringLatest(this.sensorIDs)
    },
    loadSensorMonitoring () {
      this.loading = true
      return Promise.all([
        this.$http.get(`/api/v1/sensor-monitoring?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}`)
          .then(body => body?.data?.data),
        this.$http.get(`/api/v1/sensor-monitoring/bandwidth?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}`)
          .then(body => body?.data?.data)
      ]).then(outputs => {
        if (!outputs || !outputs[0]) {
          return
        }

        const sensors = {}
        Object.keys(outputs[0]).forEach(sensorID => {
          const sensor = outputs[0][sensorID]
          const sensorSeries = Object.values(sensor.series)
          const sensorBandwidth = outputs[1][sensorID]
          const sensorInterfaces = sensorBandwidth.series

          const output = {
            id: sensorID,
            name: sensor.name,
            resourceLatest: {},
            bandwidth: {}
          }

          if (!sensorInterfaces || !Object.keys(sensorInterfaces).length) {
            output.resourceSeries = statsSeriesTimeseries(sensorSeries.map(item => {
              return {
                time: new Date(item.created_at),
                data: {
                  cpu: item.cpu,
                  disk: item.disk,
                  ram: item.ram
                }
              }
            }))

            sensors[sensorID] = output
            return
          }

          const interfaces = Object.keys(sensorInterfaces)
          const bandwidthAxes = interfaces.reduce((acc, val) => {
            acc[val] = 'y2'
            return acc
          }, {})

          output.resourceSeries = statsSeriesTimeseries(sensorSeries.map(item => {
            const bandwidth = interfaces.reduce((acc, val) => {
              acc[val] = sensorInterfaces[val][item.created_at]?.received_bytes_avg || 0
              return acc
            }, {})

            return {
              time: new Date(item.created_at),
              data: {
                cpu: item.cpu,
                disk: item.disk,
                ram: item.ram,
                ...bandwidth
              }
            }
          }), null, {
            axes: bandwidthAxes
          })
          sensors[sensorID] = output
        })

        this.sensors = sensors
      }).finally(() => { this.loading = false })
    },
    loadStatsOverview () {
      this.$http.get(`/api/v1/stats/overview?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}`)
        .then(body => {
          return body && body.data
        })
        .then(data => {
          if (!data) {
            return
          }

          this.statsOverview = data.data
          this.loading = false
        })
    },
    loadStatsAllWithRange () {
      this.loading = true
      return this.$http.get(`/api/v1/stats?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}&range=auto&limit=10`)
        .then(body => {
          return body && body.data
        })
        .then(data => {
          if (!data) {
            return
          }

          this.statsAllWithRange = data.data
          this.loading = false
        })
    },
    loadStatsSeverity () {
      this.loading = true
      return this.$http.get(`/api/v1/stats/severity?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}`)
        .then(body => {
          return body && body.data
        })
        .then(data => {
          if (!data) {
            return
          }

          this.statsSeverity = data.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadStatsResource () {
      this.loading = true
      return this.$http.get(`/api/v1/stats/resource?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}&limit=10`)
        .then(body => {
          return body && body.data
        })
        .then(data => {
          if (!data) {
            return
          }

          this.statsResource = data.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadStatsSrc () {
      this.loading = true
      return this.$http.get(`/api/v1/stats/src?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}&limit=10`)
        .then(body => {
          return body && body.data
        })
        .then(data => {
          if (!data) {
            return
          }

          this.statsSrc = data.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadStatsDstGeo () {
      this.loading = true
      return this.$http.get(`/api/v1/stats/dst?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}&limit=100&geo=true`)
        .then(body => {
          return body && body.data
        })
        .then(data => {
          if (!data) {
            return
          }

          this.foobar = data.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadRecentThreats () {
      return this.$http.get('/api/v1/threats?limit=20&sort=seen_at&order=desc')
        .then(body => body && body.data)
        .then(data => {
          if (!data) {
            return
          }

          this.recentThreats = data?.data?.items || []
        })
    },
    loadRecentAlerts () {
      return this.$http.get('/api/v1/alerts?limit=20')
        .then(body => body && body.data)
        .then(data => {
          if (!data) {
            return
          }

          this.recentAlerts = data?.data?.items || []
        })
    }
  }
}
</script>
<style lang="scss">
@use 'sass:math';
@import "bulma/sass/utilities/mixins.sass";
@import "bulma/sass/utilities/derived-variables.sass";

.stats {
  padding: 0 1em;

  @media (min-width: 1408px) {
    padding: 0 3rem;
  }

}

.foobar {
  gap: 1.5rem;
  > div {
    &:last-child {
      @include fullhd {
        width: 20rem!important;
      }
    }
  }
}
</style>
